import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 350">
    <title>Loader Logo</title>
    <g data-name="Layer 1">
      <g id="B" transform="translate(26.000000, 19.000000)">
        <path
          className="cls-1"
          d="M129.73,27.07s-.09.01-.14.01c.02-.14.05-.28.07-.42.02.14.05.27.07.41Z"
        />
        <polygon className="cls-1" points="129.66 26.65 129.66 26.65 129.66 26.66 129.66 26.65" />
        <polygon className="cls-2" points="129.66 100.64 129.66 100.64 129.66 100.64 129.66 100.64" />
        <path
          fill="currentColor"
          className="cls-1"
          d="M129.66,100.65c-9.72,0-18.57-3.78-25.18-9.93-7.26-6.76-11.82-16.39-11.82-27.07,7.26,1.04,13.5,3.16,18.7,6.37,3.42,2.1,6.38,4.69,8.9,7.75.46.56.9,1.13,1.33,1.72.64.88,1.25,1.8,1.82,2.76s1.11,1.95,1.61,2.98c.34.68.66,1.39.97,2.1.26.62.51,1.26.75,1.9.47,1.22.88,2.49,1.26,3.79.46,1.6.86,3.26,1.2,4.99.17.86.32,1.74.46,2.64Z"
        />
        <path
          fill="currentColor"
          className="cls-1"
          d="M166.65,63.65c0,10.74-4.6,20.41-11.93,27.18-6.59,6.09-15.4,9.82-25.06,9.82,2.19-14.24,8.27-24.33,18.06-30.48,5.25-3.3,11.57-5.46,18.93-6.52Z"
        />
        <path
          fill="currentColor"
          className="cls-1"
          d="M236.83,152.04c7.42,23.85,14.89,47.71,22.44,71.57h-59.52l-34.33-101.22-4.74-14.01c14.91-10.38,24.37-28.04,23.26-47.86-1.55-27.85-24.41-50.28-52.28-51.28-31-1.11-56.37,23.67-56.37,54.41,0,18.53,9.22,34.88,23.33,44.72l-4.71,14.17-33.6,101.07H.05c4.7-15.82,12.05-39.62,22.07-71.39,6.02-18.88,11.98-37.29,17.87-55.25C26.64,87.89,13.31,76.78,0,63.65,22.64,41.31,45.33,24.83,68.08,14.24c40.92-19.05,82.04-19.03,123.35.33,22.57,10.58,45.2,26.91,67.88,49.08-13.39,13.09-26.75,24.13-40.1,33.17,5.27,16.33,11.14,34.72,17.62,55.22Z"
        />
        <path
          fill="currentColor"
          className="cls-1"
          d="M166.65,63.65c-10.44-1.5-18.79-5.23-24.96-11.26-6.21-6.07-10.22-14.48-11.96-25.32-.02-.14-.05-.27-.07-.41,20.4,0,36.99,16.59,36.99,36.99Z"
        />
        <path
          fill="currentColor"
          className="cls-1"
          d="M92.67,63.65c10.44-1.5,18.79-5.23,24.96-11.26,6.21-6.07,10.22-14.48,11.96-25.32.02-.14.05-.27.07-.41-20.4,0-36.99,16.59-36.99,36.99Z"
        />
      </g>
    </g>
  </svg>
);

export default IconLoader;
