import React, { useEffect, useState } from "react";
import getNowPlayingItem from "./spotifyAPI";
import styled from 'styled-components';
import me2 from '@images/me2.jpg';
import { FaSpotify } from "react-icons/fa";
import { Link } from "gatsby";

const StyledMusic = styled.div`
  display: flex;
  border-radius: 9999px 9999px 0 0;
  @media (max-width: 768px) {
    border-radius: 9999px 9999px 0 0;
    height: 500px;
  }
  background-color: var(--green);
  align-items: center;
  background-image: url(${me2});
  mask-image: linear-gradient(to bottom, var(--green) 50%, rgba(0,0,0,1));
  background-size: cover;
  background-position: center;
  justify-content: center;
  max-width: 100%;
  height: 350px;
  overflow: hidden;
`;

const StyledMusicSpinner = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  gap: 10px;
  height: 70px;
  justify-content: center;  
  width: 100%;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  background-color: black;
  border-radius: 0 0 30px 30px;
  box-shadow: 0 0 10px 10px var(--green);
  & > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & > div > h1 {
    margin: 0;
    animation: marquee 10s linear infinite;
  }
  & > div >h3 {
    margin: 0;
    animation: ${({ isLong }) => isLong ? 'marquee 10s linear infinite' : 'none'};
  }
  & > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: spin 5s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }

  @keyframes marquee {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const NotPlaying = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  place-items: center;
  justify-content: center; 
  align-items: center;
  padding: 20px 0;
  border-radius: 0 0 30px 30px;
  box-shadow: 0 0 10px 10px var(--green);
  height: 70px;
  
  & > h1 {
    margin: 0 !important;
  }
`;

const SpotifyNowPlaying = (props) => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState({});

  useEffect(() => {
    Promise.all([
      getNowPlayingItem(
        props.client_id,
        props.client_secret,
        props.refresh_token
      ),
    ]).then((results) => {
      setResult(results[0]);
      setLoading(false);
    });
  }, []);

  const isLongArtist = result.artist && result.artist.length > 5; // Adjust the threshold as needed
  console.log(result)
  return (
    <div>
      {loading && <div>Loading...</div>}
          <div>
            <StyledMusic>
            </StyledMusic>
              {result.isPlaying ? (
              <a href={result.songUrl} target="_blank" style={{display: "block"}} >
                <StyledMusicSpinner isLong={isLongArtist}>
                      <img src={result.albumImageUrl} alt="Album Art" />

                      <div>
                        <h1>{result.title}</h1>
                      </div>

                      <div>
                        <h3>{result.artist}</h3>
                      </div> 
                </StyledMusicSpinner>
              </a>
              ) : (
                <>
                  <NotPlaying>
                    <FaSpotify color="var(--green)" size={20}/>
                    <h1>No song currently playing</h1>
                  </NotPlaying>
                </>
              )}
          </div>
    </div>
  );
};

export default SpotifyNowPlaying;
